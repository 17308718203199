import React from 'react'

const Animates = () => {
  return (
    <>
      <div className='outer'>
        <h1 className='animates'>
          G I E MAME DOUSSOU &nbsp;
          <div className='inner'>
            <span>
              <br />
              G I E MAME DOUSSOU
              <br />
              CONTACTEZ NOUS
              <br />
              AU 77 706-25-56
              <br />
            </span>
          </div>
        </h1>
      </div>
    </>
  )
}

export default Animates
